import React from 'react';

import { RunType } from '@graphql-types';
import clsx from 'clsx';

import {
  AsyncList,
  AsyncListProps,
  AsyncListState,
} from '~/shared/components/AsyncList';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';

import { useLayoutContext } from '~/services/navigation';

import { BlueprintFragment } from '~/entities/blueprints/gql/fragments/blueprint.graphql';

import { useTileSize } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { BlueprintTile } from '../BlueprintTile';

/**
 * Default skeleton items count for blueprint list
 */
export const BLUEPRINT_TILES_DEFAULT_COUNT = 4;

interface Props extends Partial<AsyncListProps<BlueprintFragment>> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * State from getAsyncListState fabric for rendering
   */
  asyncListState: AsyncListState<BlueprintFragment>;
  /**
   * Blueprints run type
   */
  runType: RunType;
  /**
   * Called, when user clicked on a button to delete blueprint
   */
  onDelete?: (blueprintId: string) => void;
}

export const BlueprintTilesList: React.FC<Props> = ({
  className,
  asyncListState,
  runType,
  onDelete,

  ...asyncProps
}) => {
  const { tileSize, tilesGridClassName } = useTileSize(runType);

  const { layoutPrimaryButtonProps } = useLayoutContext();

  return (
    <AsyncList<BlueprintFragment>
      {...{
        asyncListState,
        renderItemsWrapper: items => (
          <div
            className={clsx(
              className,
              layoutStyles.limitedContainer,
              'container-inline-size'
            )}
          >
            <div className={tilesGridClassName}>{items}</div>
          </div>
        ),
        renderNoItemsMessage: noItemsMessage => (
          <div
            className={clsx(
              className,
              layoutStyles.fillLeftoverHeightContainer,
              layoutStyles.limitedContainer,
              panelStyles.panel,
              'p-24 grid place-items-center'
            )}
          >
            {noItemsMessage}
          </div>
        ),
        noItemsMessage: (
          <DataBlockedMessage
            {...{
              isLarge: true,
              className: 'p-24',
              message: 'Команды пока не добавлены',
              buttonProps: layoutPrimaryButtonProps,
            }}
          />
        ),
        noSearchItemsMessage: (
          <DataBlockedMessage
            {...{
              isLarge: true,
              className: 'p-24',
              message: 'Команды не найдены',
              description: 'По выбранным фильтрам не найдено карточек',
            }}
          />
        ),
        renderItem: blueprint => (
          <BlueprintTile
            key={blueprint.id}
            {...{
              blueprint,
              size: tileSize,
              onDelete: () => onDelete?.(blueprint.id),
            }}
          />
        ),
        ...asyncProps,
      }}
    />
  );
};
