import {
  MilkingParlorManufacturerEnum,
  ParlorExportStatusFileTagFieldEnum,
} from '@graphql-types';

/**
 * Possible milking parlors manufacturers display names
 */
export const MILKING_PARLOR_MANUFACTURERS_DICT: Record<
  MilkingParlorManufacturerEnum,
  string
> = {
  [MilkingParlorManufacturerEnum.Gea]: 'GEA',
  [MilkingParlorManufacturerEnum.Boumatic]: 'BouMatic',
  [MilkingParlorManufacturerEnum.Dataflow]: 'DataFlow',
  [MilkingParlorManufacturerEnum.Alpro]: 'AlPro',
  [MilkingParlorManufacturerEnum.Delpro]: 'DelPro',
};

/**
 * Possible parlor tag fields display names
 */
export const TAG_FIELDS_DICT = {
  [ParlorExportStatusFileTagFieldEnum.Eid]: 'Номер электронной бирки (EID)',
  [ParlorExportStatusFileTagFieldEnum.Trans]: 'Номер транспондера (TRANS)',
};

/**
 * Milking parlors, which accept additional reg num field
 */
export const MILKING_PARLORS_WITH_REG_NUM_FIELD = [
  MilkingParlorManufacturerEnum.Dataflow,
  MilkingParlorManufacturerEnum.Alpro,
  MilkingParlorManufacturerEnum.Delpro,
];
